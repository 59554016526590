import React from 'react';
import ReferentResourceGrid from './ReferentResourceGrid';
import ReferentSearchForm from './search-form/ReferentSearchForm';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import withAuth, { WithAuthProps } from 'src/containers/withAuth';
import { useLocation, useHistory } from 'react-router-dom';
import { useAsync } from 'react-async';
import { loadReferents } from 'src/api/queries/referent';
import { Box, Link, Typography } from '@material-ui/core';

function ReferentPage(props: WithAuthProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const referentIds = search.get('referents');
  const referents: string[] = referentIds ? referentIds.split(',') : [];
  const stage = `${process.env.REACT_STAGE || 'test'}`;

  const { authStatus } = props;

  const { data, error } = useAsync<ReferentDto[]>({
    promiseFn: loadReferents,
    watch: authStatus,
    authStatus,
  });

  const allReferents = data || [];

  if (error) {
    throw new Error(`Error loading Referents: ${error}`);
  }

  if (authStatus === 'notAuthenticated') {
    return <div>Please login</div>;
  }

  return (
    <>
      <ReferentSearchForm
        selectedReferentIds={referents}
        allReferents={allReferents}
        onFormChange={(form): void => {
          const referents = form.referents;
          search.set('referents', referents.join(','));
          history.replace({ search: search.toString() })
        }}
      />
      <Box
        textAlign={'center'}
        borderColor={'#cb0101'}
        border={'solid'}
        p={'2rem'}
        mb={'2rem'}
      >
        <Typography variant="body1">
          <span aria-hidden="true">⚠️</span>
          Mit der kommenden Einsatzplanung am 17.03. wird der TAPAS nur noch
          veraltet die Daten zu Veranstaltungsblockungen anzeigen und sollte
          somit nicht mehr verwendet werden. Bitte nutzt stattdessen den&nbsp;
          {stage === 'prod' ? (
            <Link
              target="_blank"
              href="https://hive.prml.ban.haufe-akademie.de/calendar"
              underline="always"
            >
              HIVE - Buchungskalender
            </Link>
          ) : (
            <Link
              target="_blank"
              href="https://hive.prml.ban.akadev.de/calendar"
              underline="always"
            >
              HIVE - Buchungskalender
            </Link>
          )}
          <span aria-hidden="true">⚠️</span>
        </Typography>
      </Box>
      <ReferentResourceGrid
        selectedReferentIds={referents}
        allReferents={allReferents}
      />
    </>
  );
}

export default withAuth(ReferentPage);
